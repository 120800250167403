@import '~@aihub/ui/scss/utils/rem-calc';
@import '~@aihub/ui/scss/colors';

.deloitteai--header {

  &__list {
    margin-bottom: rem-calc(42px);
  }

  &__list .title {
    font-family: OpenSansBold;
  }

  &__list .counter {
    background-color: $deloitte-green;
    border-radius: 50%;
    height: rem-calc(28px);
    width: rem-calc(28px);
    padding-top: rem-calc(1.5);
    font-size: rem-calc(15px);
    text-align: center;
    vertical-align: middle;
    color: $white;
    font-weight: normal;
  }
}
