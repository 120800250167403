/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/form";
@import "./assets/scss/button";
@import './assets/scss/nucleo';
@import './assets/scss/nucleo_icons';
@import "./assets/scss/ui_elements";

html, body {
  height: 100%;
}
