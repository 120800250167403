@import "~@aihub/ui/scss/utils/rem-calc";
@import "../colors.scss";

.btn {
  font-family: OpenSansSemibold;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
  font-size: rem-calc(16px);
  padding: 0 1.4rem !important;
  border-radius: rem-calc(2px) !important;
  border-color: transparent;
  height: rem-calc(50px);
  box-shadow: none;

  a {
    color: inherit;
    text-decoration: none;
  }

  /**
   * loading button
   */
  &.loading {
    opacity: 0.45;

    .mat-progress-spinner,
    .mat-spinner {
      margin-left: rem-calc(10px);
    }
  }

  /**
   * disabled button
   */
  &:disabled:not(.loading) {
    background: #96999b !important;
    opacity: initial !important;
    border: none !important;
  }

  /**
   * primary button
   */
  &.btn-primary:disabled,
  &.btn-primary {
    color: $white;
    background-color: $btn-primary;

    &:hover {
      background-color: rgba(98, 181, 229, 0.7);
    }

    &.loading {
      .mat-progress-spinner circle,
      .mat-spinner circle {
        stroke: $white;
      }
    }
  }

  /**
   * btn secondary
   */
  &.btn-secondary {
    border: 1px solid $btn-primary !important;
    background: transparent !important;
    color: $btn-primary;

    &:hover {
      background-color: rgba(98, 181, 229, 0.7);
      color: $white;
    }

    &:disabled {
      border: 1px solid #96999b !important;
      background: transparent !important;
      color: #96999b !important;
    }

    &:focus,
    &.focus {
      background-color: rgba(98, 181, 229, 0.7) !important;
      color: $white;
    }

    &.loading {
      .mat-progress-spinner circle,
      .mat-spinner circle {
        stroke: $btn-primary;
      }
    }
  }
}
