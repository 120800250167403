// Dropdowns - Mat select

mat-select,
.mat-select {
    border: 1px solid $border-color;
    border-radius: 2px;
    padding: 13px 18px;
    width: 100%;
    display: block;
    box-shadow: 0 5px 5px 0 #e4e4e6;
    line-height: 24px;
}

mat-option,
.mat-option {
    margin-bottom: 8px;
    border-left: 4px solid transparent;
    font-family: OpenSansRegular !important;
    padding-left: 1rem !important;
    line-height: 2.1rem !important;
    font-size: 0.9rem !important;
    height: 2.1rem !important;
    &.mat-active {
        background: transparent !important;
        color: $deloitte-green;
        font-weight: bold;
        border-left: 4px solid $deloitte-green;
    }
}